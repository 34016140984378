/**
 * Новогоднее оформление ( snowflake ) v 2014-2015
 * TODO : допилить сборку этого файла
 ====================================================
 */

import '@/styles/snow.scss'

import '@/helpers/h.dom'
import userAgent from '@/helpers/h.user-agent'
import '@/helpers/h.raf' // requestAnimationFrame
// import { LS } from '@/utils/storage'

/**
 * Start falling snow on canvas
 * @param  {element} canvas
 */
function runSnow(canvas) {
  var flakes = [],
    ctx = canvas.getContext('2d'),
    flakeCount = 200,
    mX = -100,
    mY = -100

  function snow() {
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    for (var i = 0; i < flakeCount; i++) {
      var flake = flakes[i],
        x = mX,
        y = mY,
        minDist = 150,
        x2 = flake.x,
        y2 = flake.y

      var dist = Math.sqrt((x2 - x) * (x2 - x) + (y2 - y) * (y2 - y)),
        dx = x2 - x,
        dy = y2 - y

      if (dist < minDist) {
        var force = minDist / (dist * dist),
          xcomp = (x - x2) / dist,
          ycomp = (y - y2) / dist,
          deltaV = force / 2

        flake.velX -= deltaV * xcomp
        flake.velY -= deltaV * ycomp
      } else {
        flake.velX *= 0.98
        if (flake.velY <= flake.speed) {
          flake.velY = flake.speed
        }
        flake.velX += Math.cos((flake.step += 0.05)) * flake.stepSize
      }

      ctx.fillStyle = 'rgba(255,255,255,' + flake.opacity + ')'
      flake.y += flake.velY
      flake.x += flake.velX

      if (flake.y >= canvas.height || flake.y <= 0) {
        reset(flake)
      }

      if (flake.x >= canvas.width || flake.x <= 0) {
        reset(flake)
      }

      ctx.beginPath()
      ctx.arc(flake.x, flake.y, flake.size, 0, Math.PI * 2)
      ctx.fill()
    }
    requestAnimationFrame(snow)
  }

  function reset(flake) {
    flake.x = Math.floor(Math.random() * canvas.width)
    flake.y = 0
    flake.size = Math.random() * 3 + 2
    flake.speed = Math.random() * 1 + 0.5
    flake.velY = flake.speed
    flake.velX = 0
    flake.opacity = Math.random() * 0.5 + 0.3
  }

  function init() {
    for (var i = 0; i < flakeCount; i++) {
      var x = Math.floor(Math.random() * canvas.width),
        y = Math.floor(Math.random() * canvas.height),
        size = Math.random() * 3 + 2,
        speed = Math.random() * 1 + 0.5,
        opacity = Math.random() * 0.5 + 0.3

      flakes.push({
        speed: speed,
        velY: speed,
        velX: 0,
        x: x,
        y: y,
        size: size,
        stepSize: Math.random() / 30,
        step: 0,
        angle: 180,
        opacity: opacity
      })
    }

    snow()
  }

  // canvas.addEventListener("mousemove", function(e) {
  //   mX = e.clientX,
  //   mY = e.clientY
  // });

  init()
}

/**
 * Create canvas element
 */
const createCanvas = el => {
  el = document.createElement('canvas')
  document.body.appendChild(el)
  el.classList.add('js-snow-canvas', 'snow-canvas')
  el.width = window.innerWidth
  el.height = window.innerHeight
  return el
}

const snowBtn = qs('.js-snow-btn')

/**
 * Init onload, if possible
 */
const ls = window.localStorage
if (!ls['snow'] || ls.snow == 'on') {
  ls.setItem('snow', 'on')
  runSnow(createCanvas())
  snowBtn.classList.add('is-active')
}

/**
 * Enable/disable falling snow
 */
snowBtn.on(
  'click',
  e => {
    e.preventDefault()
    // Disable
    if (ls.snow == 'on') {
      ls.setItem('snow', 'off')
      snowBtn.classList.remove('is-active')
      snowBtn.innerHTML = 'Включить снег'
      qs('.js-snow-canvas').remove()
    } else if (ls.snow == 'off') {
      // Enable
      ls.setItem('snow', 'on')
      snowBtn.classList.add('is-active')
      snowBtn.innerHTML = 'Выключить снег'
      runSnow(createCanvas())
    }
  },
  false
)
